<template>
    <v-alert
      v-if="getAlert.message"
      prominent
      type="error"
      :color="(getAlert.type === 'error' ? getColors.error : getColors.warning)"
    >
        <v-row align="center">
            <v-col class="grow">
                {{ getAlert.message }}
            </v-col>
        </v-row>
    </v-alert>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'Alert',
    computed: {
        ...mapGetters([
            'getColors',
            'getAlert',
        ]),
    },
}
</script>
