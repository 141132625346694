<template>
    <div class="w-100 fill-height d-flex flex-column align-center">
        <div style="margin-top: 20vh">
            <v-card width="500px" class="pa-3 elevation-5">
                <v-card-title>
                    <h1 class="display-1">{{ loginTitle }}</h1>
                </v-card-title>
                <v-card-text>
                    <v-form autocomplete="off" ref="submitForm" v-model="formValidity">
                        <v-text-field
                            ref="username"
                            :label="$t('common.labels.username')"
                            v-model="username"
                            prepend-icon="mdi-account-circle"
                            required
                            :rules="usernameRules"
                            @click="error = false"
                            @change="resetAlert"
                            @focus="resetAlert"
                        />
                        <v-text-field
                            ref="password"
                            v-model="password"
                            :type="showPassword ? 'text' : 'password'"
                            :label="$t('common.labels.password')"
                            prepend-icon="mdi-lock"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="showPassword = !showPassword"
                            required
                            :rules="passwordRules"
                            @click="error = false"
                            @change="resetAlert"
                            @focus="resetAlert"
                            v-on:keyup.enter="login"
                        />
                    </v-form>
                    <v-row>
                        <v-col v-for="(checkbox, index) in getCheckboxes" :kex="`checkboxIndex${index}`">
                            <v-checkbox
                                ripple
                                dense
                                v-model="checkbox.vModel"
                                :color="checkbox.color"
                                :label="checkbox.label"
                                :value="true"
                                hide-details
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider/>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        text
                        :loading="loader"
                        :color="$store.getters.getColors.secondary1"
                        @click="login"
                        :disabled="!formValidity || loader"
                    >
                        Login
                    </v-btn>
                </v-card-actions>
            </v-card>
        </div>
        <div style="width: 500px" class="mt-2">
            <Alert class="elevation-5"/>
        </div>
    </div>
</template>

<script>
import Alert from '@/components/Allgemein/Alert'

export default {
    name: 'Login',
    metaInfo: {
        title: 'Login',
    },
    components: {
        Alert,
    },
    data() {
        return {
            loader: false,
            labelRemeberMe: '',
            username: (this.$cookies.isKey('user') && this.$cookies.get('user').username ? this.$cookies.get('user').username : ''),
            extendedSession: (this.$cookies.isKey('user') ? this.$cookies.get('user').extendedSession : false),
            showPassword: false,
            password: '',
            formValidity: false,
            error: true,
            msg: '',
        }
    },
    computed: {
        getCheckboxes: function () {
            return [
                {
                    vModel: this.extendedSession,
                    color: this.$store.getters.getColors.secondary1,
                    label: this.$i18n.t('common.labels.longSession')
                },
                {
                    vModel: this.setCookies,
                    color: this.$store.getters.getColors.secondary1,
                    label: this.labelRemeberMe
                }
            ]
        },
        loginTitle: function () {
            return (this.$cookies.isKey('user') && this.$cookies.get('user').firstname ? this.$i18n.t('login.welcomeTitle', { name: this.$cookies.get('user').firstname }) : this.$i18n.t('login.login'))
        },
        usernameRules: function () {
            return [
                value => !!value || this.$i18n.t('common.validation.userNameRequired'),
            ]
        },
        passwordRules: function () {
            return [
                value => !!value || this.$i18n.t('common.validation.passwordRequired'),
            ]
        },
        setCookies: {
            get() {
                const optionals = this.$cookies.isKey('optionals')

                if (!optionals)
                    this.labelRemeberMe = this.$i18n.t('common.labels.rememberMe')
                else
                    this.labelRemeberMe = this.$i18n.t('common.labels.youreIn')

                return optionals
            },
            set(newVal) {
                if (newVal) {
                    this.$cookies.set('optionals', true)
                    this.labelRemeberMe = this.$i18n.t('common.labels.youreIn')
                }
                else if (!newVal && this.$cookies.isKey('optionals')) {
                    this.$cookies.remove('optionals')
                    this.labelRemeberMe = this.$i18n.t('common.labels.rememberMe')
                }
            },
        },
    },
    methods: {
        resetAlert() {
            this.$store.dispatch('resetProps', 'alert')
            this.loader = false
        },
        async login() {
            this.loader = true

            await this.$store.dispatch('login', {
                username: this.username,
                password: this.password,
                setCookies: this.setCookies,
                extendedSession: this.extendedSession,
            })

            this.loader = false
        },
    },
    created() {
        this.loader = false
    }
}
</script>